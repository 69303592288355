export class ParsedJWT {
  public practice_id: string;
  public practice_name: string;
  public access_level: string;
  public version: string;
  public user_id: number; // unique within the practice but reused for different practices
  public user_uuid: string; // globally unique - needed to track metrics
  public permission_level: number;
  public admin_url: string;
  public iat: Date;
  public exp: Date;
  public sid: string;
  public user_fullname: string;
  public mock_mode: string;

  public is_impersonating = false;

  public permissions: string;
  public jwtString: string;

  public constructor(parsedJWT: any) {
    // Don't use lodash because this is shared with the backend
    Object.keys(parsedJWT).forEach((key) => (this[key] = parsedJWT[key]));
  }
}
