export namespace Enums {
  export enum AUTH {
    LOGIN_URL = '/verification/v1/admin/login',
  }
  export enum DEVICES {
    IPHONE_14_PRO = 'IPHONE_14_PRO',
  }

  export enum E_DEVICES_SIZE {
    LG = 'LG',
    MD = 'MD',
    SM = 'SM',
  }

  // These can be used when the devices are being used in a layout where we need to control the spacing of the surrounding elements
  export enum E_DEVICES_SCALE_CLASSES {
    IPHONE_14_PRO_LG = '',
    IPHONE_14_PRO_MD = '-mb-[651px] w-[321px]',
    IPHONE_14_PRO_SM = '-mb-[434px] w-[214px]',
  }
}
