import { SHARED } from 'src/app/shared/shared';
import { Component, Input, OnInit } from '@angular/core';

export interface I_AvatarEntry {
  id?: string;
  first_name: string;
  last_name: string;
  image_url?: string | null;
  image_base64?: string | null;
  bg_class?: string;
  bg_disabled_class?: string;
  text_class?: string;
  text_disabled_class?: string;
}

@Component({
  selector: 'dentr-avatar',
  templateUrl: './avatar.component.html',
  host: { class: 'overflow-hidden' },
  standalone: true,
  imports: [SHARED],
})
export class AvatarComponent implements OnInit {
  // We need to declare these individually so that Tailwind can pick up the class names
  public avatarHeight: string;
  public avatarWidth: string;
  public textSizeClass: string;

  @Input() avatar: I_AvatarEntry;
  @Input() use_initials? = false;
  @Input() disabled? = false;
  @Input() size?: string;

  private _calculateSize(): void {
    switch (this.size) {
      case 'xs':
        this.avatarHeight = 'h-8';
        this.avatarWidth = 'w-8';
        this.textSizeClass = 'text-sm';
        break;
      case 'sm':
        this.avatarHeight = 'h-10';
        this.avatarWidth = 'w-10';
        this.textSizeClass = 'text-base';
        break;
      case 'lg':
        this.avatarHeight = 'h-32';
        this.avatarWidth = 'w-32';
        this.textSizeClass = 'text-4xl';
        break;
      default:
        this.avatarHeight = 'h-16';
        this.avatarWidth = 'w-16';
        this.textSizeClass = 'text-2xl';
        break;
    }
  }

  public ngOnInit(): void {
    this._calculateSize();

    if (!this.avatar.bg_class) {
      this.avatar.bg_class = 'bg-gray-200';
      this.avatar.bg_disabled_class = 'bg-gray-100';
    }

    if (!this.avatar.text_class) {
      this.avatar.text_class = 'text-gray-700';
      this.avatar.text_disabled_class = 'text-gray-200';
    }
  }
}
