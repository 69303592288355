<div class="relative">
  <dentr-dev-button
    *ngIf="commonService.selectedSiteId != commonService.ALL_SITES.id"
    [item_id]="commonService.selectedSiteId"
    [item_type]="'Site'"
  ></dentr-dev-button>
  <div class="relative block">
    <label for="site_switcher" class="sr-only">Site selector</label>
    <ng-select
      data-unit-test-site-switcher
      data-e2e-site-switcher
      class="ng-select-site-switcher"
      [ngClass]="{ 'ng-select-site-switcher-inverted': inverted }"
      labelForId="'site_switcher'"
      #ngSelect
      placeholder="Find a site..."
      [virtualScroll]="true"
      [clearable]="false"
      [clearOnBackspace]="false"
      [(ngModel)]="selectedSiteId"
      (change)="onChange($event)"
      [searchable]="commonService.isMultiSite"
    >
      <ng-option *ngFor="let site of sites" [value]="site.id">
        <div [attr.data-e2e-site-switcher-option]="site.id">
          <span *ngIf="site.id !== commonService.ALL_SITES.id">{{ site.name }}</span>
          <span
            class="ng-select-site-switcher-all-sites flex items-center"
            data-e2e-site-switcher-option-all-sites
            *ngIf="site.id === commonService.ALL_SITES.id"
          >
            <ng-icon name="heroRectangleStack" size="20" class="mr-2"></ng-icon>
            {{ site.name }}
          </span>
        </div>
      </ng-option>
    </ng-select>
  </div>
</div>
