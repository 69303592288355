import { forOwn } from "lodash";

export class BrandCustomDomainBase {
  public brand_id: string;
  public custom_domain_id: string;

  public constructor(baseItem?: any) {
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
