import { forOwn } from "lodash";

export class BrandDefaultDomainBase {
  public brand_id: string;
  public default_domain_id: string;
  public subdomain: string;

  public constructor(baseItem?: any) {
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
