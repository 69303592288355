<div
  class="absolute w-{{ width }} card z-50"
  [ngClass]="{
    'right-0 origin-top-right': anchor === 'left',
    'left-0 origin-top-left': anchor === 'right'
  }"
  role="menu"
  aria-orientation="vertical"
  tabindex="-1"
>
  <div role="none">
    <ng-content></ng-content>
  </div>
</div>
