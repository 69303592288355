<div class="fixed left-1/2 top-5 z-50 w-4/5 -translate-x-2/4 sm:w-auto">
  <div
    *ngIf="notification"
    [@toastTopAnimation]
    class="rounded-md p-4 shadow-2xl"
    aria-live="assertive"
    [ngClass]="{
      'bg-yellow-50': isWarning,
      'bg-ruby-50': isError,
      'bg-pine-50': isSuccess,
      'bg-blue-50': isInfo
    }"
  >
    <span
      role="button"
      (click)="close()"
      class="absolute top-[6px] right-[9px] cursor-pointer"
      [ngClass]="{
        'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50': isWarning,
        'text-ruby-500 bg-ruby-50 hover:bg-ruby-100 focus:ring-offset-ruby-50 focus:ring-ruby-600': isError,
        'text-pine-500 bg-pine-50 hover:bg-pine-100 focus:ring-offset-pine-50 focus:ring-pine-600': isSuccess,
        'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50': isInfo
      }"
    >
      <span class="sr-only">Dismiss</span>
      <ng-icon name="heroXMark" size="20"></ng-icon>
    </span>

    <div class="grid grid-cols-12 items-center gap-x-2">
      <div class="col-span-1">
        <ng-icon name="heroExclamationCircleSolid" size="20" class="text-ruby-400" *ngIf="isError"></ng-icon>
        <ng-icon name="heroExclamationTriangleSolid" size="20" class="text-yellow-400" *ngIf="isWarning"></ng-icon>
        <ng-icon name="heroInformationCircleSolid" size="20" class="text-blue-400" *ngIf="isInfo"></ng-icon>
        <ng-icon name="heroCheckCircleSolid" size="20" class="text-pine-400" *ngIf="isSuccess"></ng-icon>
      </div>
      <h3
        class="col-span-11 font-medium"
        [innerHtml]="notification.title"
        [ngClass]="{
          'text-yellow-800': isWarning,
          'text-ruby-800': isError,
          'text-pine-800': isSuccess,
          'text-blue-800': isInfo
        }"
      ></h3>
      <p
        *ngIf="notification.body"
        class="col-span-11 col-start-2 mt-1 break-all"
        [innerHtml]="notification.body"
        [ngClass]="{
          'text-yellow-700': isWarning,
          'text-ruby-700': isError,
          'text-pine-700': isSuccess,
          'text-blue-700': isInfo
        }"
      ></p>
    </div>
    <div class="mt-1.5 ml-6" *ngIf="notification.actionFn">
      <button
        *ngIf="notification.actionFn"
        type="button"
        (click)="close(notification.actionFn)"
        [ngClass]="{
          'bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-yellow-600 focus:ring-offset-yellow-50': isWarning,
          'text-ruby-500 bg-ruby-50 hover:bg-ruby-100 focus:ring-offset-ruby-50 focus:ring-ruby-600': isError,
          'text-pine-500 bg-pine-50 hover:bg-pine-100 focus:ring-offset-pine-50 focus:ring-pine-600': isSuccess,
          'bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-blue-600 focus:ring-offset-blue-50': isInfo
        }"
        class="whitespace-nowrap rounded-md px-2 py-1.5 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
      >
        {{ notification.actionText }}
      </button>
    </div>
  </div>
</div>
