import { SHARED } from 'src/app/shared/shared';
import { Component, OnInit } from '@angular/core';
import { TooltipPosition, TooltipTheme } from './tooltip.directive';

@Component({
  selector: 'dentr-tooltip',
  templateUrl: './tooltip.component.html',
  standalone: true,
  imports: [SHARED],
})
export class TooltipComponent implements OnInit {
  public position: TooltipPosition = TooltipPosition.DEFAULT;
  public theme: TooltipTheme = TooltipTheme.DEFAULT;
  public tooltip = '';
  public left = 0;
  public top = 0;
  public visible = false;

  ngOnInit(): void {}
}
