import { Component } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroXCircle } from '@ng-icons/heroicons/outline';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'dentr-portal-santa',
  templateUrl: './portal-santa.component.html',
  standalone: true,
  imports: [NgIconComponent],
  providers: [provideIcons({ heroXCircle })],
})
export class PortalSantaComponent {
  constructor(private _commonService: CommonService) {}

  public hide(): void {
    this._commonService.showSanta = false;
  }
}
