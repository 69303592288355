import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public get hostname(): string {
    return window.location.hostname;
  }

  public get region(): string {
    if (this.hostname.includes('.ca.')) {
      return 'ca-central-1';
    }

    return 'eu-west-1';
  }
}
