import { forOwn } from "lodash";
import { DENTALLY_BLUE_COLOUR } from "../constants";

export interface I_BrandCustomDomain {
  custom_domain_id: string;
}

export interface I_BrandDefaultDomain {
  default_domain_id: string;
  subdomain: string;
}

export function isCustomDomain(object: I_BrandCustomDomain | I_BrandDefaultDomain): object is I_BrandCustomDomain {
  return "custom_domain_id" in object;
}

export enum E_BrandOwner {
  PRACTICE = "Practice",
  SITE = "Site",
}

export class BrandBase {
  public appointment_list_first: boolean;
  public colour = DENTALLY_BLUE_COLOUR;
  public default_uri: string;
  public display_name: string;
  public favicon_preview_url: string | null;
  public ga_tracking_cookie_domain: string | null;
  public ga_tracking_id: string | null;
  public ga_tracking_link_domain: string | null;
  public id: string;
  public logo_url: string;
  public override_practice_brand_theme: boolean;
  public owning_site_id: string | null;
  public practice_id: string;
  public privacy_policy_url: string | null;
  public readonly subdomain: string;
  public readonly uri: string;
  public site_locking_enabled: boolean;

  private get _derivedSubdomain(): string {
    if (!this.uri || this.uri === "") return "";
    return this.uri.split(".")[0];
  }

  public get default_url(): string {
    if (!this.default_uri || this.default_uri === "") return "";
    return `https://${this.default_uri}`;
  }

  public get url(): string {
    if (!this.uri || this.uri === "") return "";
    return `https://${this.uri}`;
  }

  public get owner(): E_BrandOwner {
    if (this.owning_site_id) {
      return E_BrandOwner.SITE;
    }
    return E_BrandOwner.PRACTICE;
  }

  public constructor(baseItem?: any) {
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      if (key === "owner" || key === "default_url" || key === "url") return;
      this[key] = value;
    });

    if (!this.subdomain) this.subdomain = this._derivedSubdomain;
  }
}
