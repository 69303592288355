import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CommonService } from './common.service';
import { JWTService } from './jwt.service';
import { SessionService } from './session.service';

@Injectable()
export class AppInitService {
  constructor(private _jwtService: JWTService, private _sessionService: SessionService, private _commonService: CommonService) {}

  public async init(): Promise<void> {
    try {
      await this._sessionService.init();
      await this._jwtService.initSession();
      this._commonService.initPracticeData();
      await firstValueFrom(this._commonService.onPracticeDataReady);
    } catch (error) {
      console.log('Failed to initialize session - logging out');

      await this._sessionService.logout();

      throw error;
    }
  }
}
