import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie.service';
import { Constants } from 'src/constants';
import { ParsedJWT } from '../models/parsed-jwt';
import { getGlobalDomain } from '@shared/new-domain';
import { Subject } from 'rxjs';
import { isE2E } from '../utils/is-e2e';
import { NavigationService } from './navigation.service';
import { LocationService } from './location.service';

export const STAGE_STORAGE_NAME = 'stage';
export const REGION_STORAGE_NAME = 'region';

@Injectable({
  providedIn: 'root',
})
export class JWTService {
  private _jwt: string | null;

  public onSessionIdChanged: Subject<string> = new Subject<string>();

  constructor(
    private _localStorageService: LocalStorageService,
    private _cookieService: CookieService,
    private _httpService: HttpService,
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _locationService: LocationService
  ) {}

  public get jwt(): string | null {
    if (isE2E()) return window.jwt;

    return this._jwt;
  }

  public set jwt(jwt: string | null) {
    this._jwt = jwt;

    if (jwt) {
      this.onSessionIdChanged.next(this.getJWT().sid);
    }

    if (isE2E()) window.jwt = jwt;
  }

  public replaceJWT(jwt: string): void {
    this.jwt = jwt;
  }

  public getJWTString(): string {
    return this.jwt as string;
  }

  public getJWT(): ParsedJWT {
    const jwt = this.getJWTString();
    const parsedJWT = this._parseJwt(jwt);
    return parsedJWT;
  }

  public clearJWT(): void {
    this.jwt = null;
  }

  public logOut() {
    this.jwt = null;
    this._localStorageService.removeItem(STAGE_STORAGE_NAME);
    this._cookieService.deleteCookie(Constants.SITE_SELECTION_COOKIE);
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const path = urlParams.get('path');
      const domain = getGlobalDomain(environment.IS_PROD);
      const { region } = this._locationService;
      const qs = new URLSearchParams();

      let url = `https://${environment.IS_PROD ? 'p' : 'd'}-${region}-region-api.${domain}/oauth/login/dentally`;

      if (path) qs.append('path', path);
      if (!['sandbox', 'development', 'production'].includes(environment.STAGE)) qs.append('stage', environment.STAGE);

      if (qs.toString()) url += `?${qs}`;

      window.location.href = url;
    }, 100);
  }

  public get practiceId(): string | null {
    const parsedJWT = this.getJWT();
    if (parsedJWT) return parsedJWT.practice_id;
    return null;
  }

  public async initSession() {
    const urlParams = new URLSearchParams(window.location.search);
    const sid = urlParams.get('sid');
    const setup = urlParams.get('setup');
    const path = urlParams.get('path');

    this._handleStageAndRegion(urlParams);

    const jwt = this.getJWT();
    // Don't continue with the existing jwt if the url sid is different (e.g. impersonating a different user)
    if (jwt.practice_id && (!sid || jwt.sid === sid)) {
      if (path) this._handlePath(path);
      return true;
    }

    return this._performLogin(sid, path, setup);
  }

  private _handlePath(path: string): void {
    const route = path ? this._generatePath(path) : '.';
    this._navigationService.navigate(route, { relativeTo: this._route, queryParams: {} });
  }

  private _handleStageAndRegion(urlParams: URLSearchParams): void {
    let region = urlParams.get('region');
    let stage = urlParams.get('stage');

    if (stage && region) {
      window.REGION = region || environment.REGION;
      window.STAGE = stage || environment.STAGE;

      this._localStorageService.setItem(REGION_STORAGE_NAME, region);
      this._localStorageService.setItem(STAGE_STORAGE_NAME, stage);
    } else {
      region = this._localStorageService.getItem(REGION_STORAGE_NAME);
      stage = this._localStorageService.getItem(STAGE_STORAGE_NAME);

      if (region) {
        window.REGION = region;
      }

      if (stage) {
        window.STAGE = stage;
      }
    }
  }

  private async _performLogin(sid: string | null, path: string | null, setup: string | null): Promise<boolean> {
    if (!sid) throw new Error('Missing sid');

    const data: { jwt: string; success: boolean; error?: { msg: string; code: string; stages?: string[] } } = await this._httpService.post_rest(
      '/verification/manage/login',
      { sid, setup }
    );
    if (!data.success && data.error) {
      alert(`Error: ${data.error.msg}\n${data.error.stages ? '(Setup stages: ' + data.error.stages.join(', ') + ')' : ''} `);
      this.logOut();
      return false;
    }
    if (data.success && data.jwt) {
      this.jwt = data.jwt;
      if (path) this._handlePath(path);
      return true;
    }
    alert('Error: unknown error getting session');
    return false;
  }

  private _generatePath(path: string): string {
    return path.replace(/_/g, '/');
  }

  private _parseJwt(token: string): any {
    if (!token) {
      return false;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const result = JSON.parse(window.atob(base64));
    result.jwtString = token;
    return new ParsedJWT(result);
  }
}
