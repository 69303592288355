import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { APP_ROUTES } from './app/app-routing';
import { AppComponent } from './app/app.component';
import { DevService } from './app/shared/services/dev.service';
import { FeatureGuard } from './app/shared/services/guards/feature-guard';
import { HttpRequestInterceptor } from './app/shared/services/http-interceptor';
import { NotificationService } from './app/shared/services/notification.service';
import { environment } from './environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CurrencyPipe } from '@angular/common';
import { ColorPickerService } from 'ngx-color-picker';
import { AppInitService } from './app/shared/services/app-init.service';

const BUGSNAG_API_KEY = '69a2abe24e7a828edcb51f608e57aab0';

if (environment.production) {
  enableProdMode();
}

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  collectUserIp: false,
  appVersion: environment.VERSION || 'unset',
  releaseStage: environment.STAGE ? environment.STAGE : 'unset',
  onError: (event) => {
    console.warn('Bugsnag error', event);
  },
});

BugsnagPerformance.start(BUGSNAG_API_KEY);

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    provideRouter(APP_ROUTES),
    FeatureGuard,
    AppInitService,
    {
      provide: APP_INITIALIZER, // see https://angular.io/api/core/APP_INITIALIZER
      useFactory: (appInitService: AppInitService) => (): Promise<any> => appInitService.init(),
      deps: [AppInitService],
      multi: true,
    },
    NotificationService,
    ColorPickerService,
    DevService,
    CurrencyPipe,
    importProvidersFrom(BrowserAnimationsModule, HttpClientModule, AngularSvgIconModule.forRoot()),
  ],
}).catch((err) => console.error(err));
