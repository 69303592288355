import { SHARED } from 'src/app/shared/shared';
import { Component, OnInit } from '@angular/core';
import { OverlayService } from 'src/app/shared/services/overlay.service';
import { DevService } from '../../services/dev.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'history-viewer-modal',
  templateUrl: './history-viewer-modal.component.html',
  standalone: true,
  imports: [ModalComponent, SHARED],
})
export class HistoryViewerModalComponent implements OnInit {
  public manage_url: string;
  public consent_text = '';

  public data: any = {};

  constructor(private _overlayService: OverlayService, private _devService: DevService) {}

  private _processHistory(): void {
    this.data.derivedFields = new Array<string>();

    for (const item of this.data.object) {
      for (const prop of Object.keys(item)) {
        if (item[prop] === null) continue;

        if (Array.isArray(item[prop])) {
          item[prop] = JSON.stringify(item[prop], null, 2);
        } else if (typeof item[prop] === 'object') {
          const itemProps = Object.keys(item[prop]);
          const nestedValue = item[prop][itemProps[0]];

          this.data.derivedFields.push(prop);

          if (itemProps.length === 1 && typeof nestedValue !== 'object' && !Array.isArray(nestedValue)) {
            // If there's just one property, show that instead
            item[prop] = nestedValue;
          } else {
            // If there's more than one property, show JSON instead
            item[prop] = JSON.stringify(itemProps[0] === 'items' ? nestedValue : item[prop], null, 2);
          }
        }
      }
    }
  }

  ngOnInit(): void {
    const { objectType, object } = this._devService.getObjectHistory();
    this.data.objectType = objectType;
    this.data.object = object;
    this.data.headers = Object.keys(object[0]);
    this.data.formattedHeaders = Object.keys(object[0]).map((k) => k.replace(/_/g, ' '));

    this._processHistory();

    this.data.changeMap = new Map();
    for (let i = 0; i < this.data.object.length - 1; i++) {
      const newItem = this.data.object[i];
      const oldItem = this.data.object[i + 1];

      Object.keys(newItem).forEach((prop) => {
        // Skip derived fields, we don't want them to appear as changes against the wrong audit records
        if (this.data.derivedFields.includes(prop)) return;

        const newValue = newItem[prop] === null || newItem[prop] === undefined ? (newItem[prop] = '') : newItem[prop].toString();
        const oldValue = oldItem[prop] === null || oldItem[prop] === undefined ? (oldItem[prop] = '') : oldItem[prop].toString();

        if (!prop.toLowerCase().startsWith('audit_') && newValue !== oldValue) {
          if (!this.data.changeMap.has(newItem)) this.data.changeMap.set(newItem, []);
          this.data.changeMap.get(newItem).push(prop);
        }
      });
    }
  }

  public close(): void {
    this._devService.clearObject();
    this._overlayService.close();
  }
}
