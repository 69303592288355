import { SHARED } from 'src/app/shared/shared';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';
import { heroExclamationCircleSolid, heroExclamationTriangleSolid, heroInformationCircleSolid, heroCheckCircleSolid } from '@ng-icons/heroicons/solid';
import { SubSink } from 'subsink';
import { NotificationService, NotificationInstance, NotificationTypes } from '../../services/notification.service';
import { TOAST_TOP_ANIMATION } from '../../utils/animations';

@Component({
  selector: 'dentr-notification',
  animations: [TOAST_TOP_ANIMATION],
  templateUrl: './notification.component.html',
  standalone: true,
  imports: [SHARED, NgIconComponent],
  providers: [provideIcons({ heroExclamationCircleSolid, heroExclamationTriangleSolid, heroInformationCircleSolid, heroCheckCircleSolid, heroXMark })],
})
export class NotificationComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();

  public notification: NotificationInstance | null;
  public isError: boolean;
  public isWarning: boolean;
  public isSuccess: boolean;
  public isInfo: boolean;

  constructor(protected notificationService: NotificationService) {}

  ngOnInit() {
    this._subs.sink = this.notificationService.onNotificationChanged.subscribe((notification) => {
      if (notification) {
        this.isError = notification.type === NotificationTypes.ERROR;
        this.isWarning = notification.type === NotificationTypes.WARNING;
        this.isSuccess = notification.type === NotificationTypes.SUCCESS;
        this.isInfo = notification.type === NotificationTypes.INFO;
        this.notification = notification;

        // Automatically close the notification if there isnt a possible user action
        if (!this.notification.actionFn) {
          setTimeout(() => {
            this.close();
          }, notification.timeout);
        }
      } else {
        this.notification = null;
      }
    });
  }

  close(callback?: () => any) {
    this.notificationService.close();
    if (callback) callback();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
