import forOwn from 'lodash/forOwn';
import { UrlSiteAliasBase } from '../../../../../backend/src/graph/url_site_aliases/url-site-alias-base';
import { BrandEntry } from './brands';
import { SiteEntry } from './site';

export class UrlSiteAliasEntry extends UrlSiteAliasBase {
  public site: SiteEntry;
  public owning_brand?: BrandEntry;
  public hidden = false;

  constructor(baseItem?: UrlSiteAliasBase, owning_brand?: BrandEntry) {
    super();
    this.owning_brand = owning_brand;
    if (!baseItem) return;

    forOwn(baseItem, (value, key) => {
      switch (key) {
        case 'alias_uri':
        case 'display_name':
          break;

        default:
          this[key] = value;
      }
    });
  }

  public get alias_uri(): string {
    return `${this.owning_brand?.derived_uri}/practices/${this.alias}`;
  }

  public get display_name(): string {
    return `${this.owning_brand?.display_name} - ${this.site.nickname || this.site.name}`;
  }
}
