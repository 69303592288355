import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export const requiredValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const { value } = control;

    // eslint-disable-next-line dentr/angular-required-validator
    if (Validators.required(control) || (typeof value === 'string' && value.trim() === '')) {
      return { customError: { message: 'This field is required' } };
    }

    return null;
  };
};
