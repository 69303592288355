import { SHARED } from 'src/app/shared/shared';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroRectangleStack } from '@ng-icons/heroicons/outline';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { CommonService } from '../../services/common.service';
import { JWTService } from '../../services/jwt.service';
import { DevButtonComponent } from '../dev-button/dev-button.component';

type T_Sites = Array<{ id: string; name: string }>;

/**
 * Checks if the sites contain a unique word from the practice name
 *
 * For example, "Mulgrave and Belmont Dental Group" has two sites "Mulgrave Dental Care" and "Belmont Dental Care" which should not be modified
 * because it will remove "Mulgrave" from the one site but not "Belmont" from the other. The site names would end up as "Dental Care" and
 * "Belmont Dental Care" which can be confusing.
 *
 * @param practiceName Name of the practice
 * @param sites Sites
 *
 * @returns Boolean
 */
function shouldModifySiteNames(practiceName: string, sites: T_Sites) {
  const practiceNameWords = practiceName.split(/[ -]+/);
  const siteNameWordsInPracticeName = sites.map((s) => s.name.split(/[ -]+/).filter((word) => practiceNameWords.includes(word)));
  const uniquePracticeNameWordsFromSiteNames = siteNameWordsInPracticeName.map((words, index) => {
    const otherSitesWord = siteNameWordsInPracticeName.filter((_word, i) => i !== index).flat();

    return words.filter((word) => !otherSitesWord.includes(word));
  });

  return uniquePracticeNameWordsFromSiteNames.some((words) => !words.length);
}

export function removePracticeNameFromSiteNames(practiceName: string, sites: T_Sites): T_Sites {
  if (!shouldModifySiteNames(practiceName, sites)) return sites;

  /*
    Create a regex which will remove any words in order from the site name which appear in the practice name.
    This allows for the site name like "Bupa Dental Care Newport" and a practice name like "Bupa Dental Care - Wales"
    In this example the regex would be /^(Bupa (Dental (Care (- Wales)?)?)?)/ig which will remove "Bupa", "Bupa Dental",
    "Bupa Dental Care" and "Bupa Dental Care - Wales" from the start of the site name
  */
  const regex = new RegExp(
    `^(${
      practiceName
        //match either of 'and' or '&' as some practices use one but have sites which use the other
        .replace(/ (and|&) /g, ' (and|&) ')
        .replace(/([\s-]+)/g, '($1') + ')?'.repeat(practiceName.split(/[ -]+/).length - 1)
    })[\\s-]+`,
    'ig'
  );

  return sites
    .map((s) => {
      // if the site name is exactly the same as the practice name, return it as is
      if (s.name.toLowerCase().trim() === practiceName.toLowerCase().trim()) return { ...s };
      const name = s.name
        .replace(regex, '') // replace any occurences of the practice name in the string, case insensitive
        .trim() // remove any whitespace
        .replace(/^(_|,|-|at )/, '') // remove any underscores, commas, hyphens or 'at ' at the beginning
        .trim(); // remove any whitespace again

      if (!name) return { ...s };

      return { id: s.id, name };
    })
    .sort((s1, s2) => (s1.name.toLowerCase() > s2.name.toLowerCase() ? 1 : -1));
}

@Component({
  selector: 'dentr-site-switcher',
  templateUrl: './site-switcher.component.html',
  standalone: true,
  imports: [DevButtonComponent, SHARED, NgSelectModule, FormsModule, NgIconComponent],
  providers: [provideIcons({ heroRectangleStack })],
})
export class SiteSwitcherComponent implements OnInit {
  @Input() localUpdates?: boolean;
  @Input() inverted?: boolean;
  @Output() onSiteChanged = new EventEmitter<string>();
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  public sites = new Array<{ id: string; name: string }>();
  public selectedSiteId = this.commonService.selectedSiteId;

  constructor(public commonService: CommonService, private _jwtService: JWTService) {}

  public ngOnInit(): void {
    for (const site of this.commonService.sites) {
      if (site.active) this.sites.push({ id: site.id, name: site.name });
    }

    this.sites = removePracticeNameFromSiteNames(this.commonService.practice.name, this.sites);

    if (this.commonService.isMultiSite === true && this._jwtService.getJWT().permission_level >= 4) {
      this.sites.unshift({ id: this.commonService.ALL_SITES.id, name: this.commonService.ALL_SITES.name });
    }

    this.commonService.onSelectedSiteChanged.subscribe((siteId: string) => {
      this.selectedSiteId = siteId;
    });
  }

  /**
   *
   * @param $event - event from ng-select
   */
  public onChange($event: string): void {
    this.ngSelect.blur();
    if (!this.localUpdates) this.commonService.selectedSiteId = $event;
    this.onSiteChanged.emit($event);
  }
}
