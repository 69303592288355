<div class="flex flex-col items-center">
  <img
    [attr.data-e2e-avatar-img]="avatar.id"
    *ngIf="avatar.image_base64 || (avatar.image_url && !use_initials)"
    class="{{ avatarWidth }} {{ avatarHeight }} shrink mx-auto rounded-full shadow-sm"
    src="{{ avatar.image_base64 || avatar.image_url }}"
    alt="{{ avatar.first_name }} {{ avatar.last_name }} display picture"
  />
  <span
    data-unit-test-avatar
    [attr.data-e2e-avatar-placeholder]="avatar.id"
    *ngIf="(!avatar.image_base64 && !avatar.image_url) || use_initials"
    class="inline-flex items-center justify-center rounded-full shadow-sm {{ avatarWidth }} {{ avatarHeight }} {{
      disabled ? avatar.bg_disabled_class : avatar.bg_class
    }}"
  >
    <span data-unit-test-avatar-name class="leading-none uppercase {{ textSizeClass }} {{ disabled ? avatar.text_disabled_class : avatar.text_class }}">
      {{ avatar.first_name.charAt(0) }}{{ avatar.last_name.charAt(0) }}
    </span>
  </span>
</div>
