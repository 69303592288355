<dentr-modal>
  <div #modalScroll>
    <div class="mb-6 flex items-center justify-between">
      <h2 class="heading-xl">Upgrade today</h2>
      <button class="button-tertiary mr-3" (click)="close()">Close</button>
    </div>
    <div class="flex flex-col items-center text-center">
      <img src="assets/dentally-illustration_booking.png" class="w-2/3" alt="Upgrade your Portal" />
      <h3 class="heading-md my-6">
        <span class="block">Your package doesn’t include this feature.</span>
        <span>Please speak to one of our team to upgrade today.</span>
      </h3>
      <button class="button-primary" (click)="openIntercom()">
        <span class="mr-3">Contact support</span>
        <ng-icon name="heroArrowSmallRight" size="20"></ng-icon>
      </button>
    </div>
  </div>
</dentr-modal>
