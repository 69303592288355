import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, map } from 'rxjs';
import { JWTService } from './jwt.service';
import Bugsnag from '@bugsnag/js';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private _http: HttpClient, private _jwtService: JWTService) {
    this._jwtService.onSessionIdChanged.subscribe(() => {
      this._setSessionId();
    });
  }

  public async init(): Promise<void> {
    try {
      const jwt = await lastValueFrom(this._getSession());

      if (!jwt) return;

      this._jwtService.jwt = jwt;
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  public async logout(): Promise<void> {
    await this._deleteSession();

    this._jwtService.logOut();
  }

  public async clear(): Promise<void> {
    await this._deleteSession();

    this._jwtService.clearJWT();
  }

  private async _deleteSession(): Promise<void> {
    try {
      await lastValueFrom(this._http.delete<void>(`/sessions`));
    } catch (error) {
      Bugsnag.notify(error);
    }
  }

  private _getSession(): Observable<string | null> {
    return this._http.get<{ jwt: string }>(`/sessions`).pipe(map((response: { jwt: string } | null) => response?.jwt || null));
  }

  private _setSession(): Observable<string | null> {
    return this._http.post<string | null>(`/sessions`, null, {
      headers: {
        Authorization: `Bearer ${this._jwtService.getJWTString()}`,
      },
    });
  }

  private _setSessionId(): void {
    try {
      this._setSession().subscribe();
    } catch (error) {
      Bugsnag.notify(error);
    }
  }
}
