import forOwn from 'lodash/forOwn';
import { LoggedInUserBase } from '../../../../../backend/src/graph/logged_in_users/logged-in-user-base';
import { I_AvatarEntry } from '../shared/components/avatar/avatar.component';
import { LoggedInUserSettingsEntry } from './logged-in-user-settings';

export class LoggedInUserEntry extends LoggedInUserBase implements I_AvatarEntry {
  public settings: LoggedInUserSettingsEntry;
  constructor(baseItem?: LoggedInUserBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });
  }
}
