<!-- Animate the stocking SVG when the group is hovered otherwise the animation could loop forever. This is due to the element losing and gaining the hover state when the cursor is at the edge of the parent element -->

<ng-container *ngIf="commonService.enableWinter">
  <div class="absolute left-10 top-16 z-10">
    <div (click)="stockingClickCount('left')" class="group cursor-pointer">
      <svg-icon class="group-hover:animate-shake w-16 cursor-pointer" [applyClass]="true" src="assets/stockings-1.svg"></svg-icon>
    </div>
    <ul *ngIf="hasVisiblePresents('left')" class="relative w-full select-none">
      <ng-container *ngFor="let item of presents.left">
        <li *ngIf="item.visible" class="animate-presentFall absolute select-none {{ item.classes }}">
          <img [src]="item.src" class="absolute w-full" alt="present" />
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="absolute right-10 top-16 z-10">
    <div (click)="stockingClickCount('right')" class="group cursor-pointer">
      <svg-icon class="group-hover:animate-shake w-16 cursor-pointer" [applyClass]="true" src="assets/stockings-2.svg"></svg-icon>
    </div>
    <ul *ngIf="hasVisiblePresents('right')" class="relative w-full select-none">
      <ng-container *ngFor="let item of presents.right">
        <li *ngIf="item.visible" class="animate-presentFall absolute select-none {{ item.classes }}">
          <img [src]="item.src" class="absolute w-full" alt="present" />
        </li>
      </ng-container>
    </ul>
  </div>
</ng-container>
