import { SHARED } from 'src/app/shared/shared';
import { Component } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroEye } from '@ng-icons/heroicons/outline';
import { Constants } from 'src/constants';
import { CommonService } from '../../services/common.service';
import { DevService } from '../../services/dev.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'dentr-impersonation',
  templateUrl: './impersonation.component.html',
  standalone: true,
  imports: [SHARED, NgIconComponent],
  providers: [provideIcons({ heroEye })],
})
export class ImpersonationComponent {
  constructor(public devService: DevService, private _navigationService: NavigationService, private _commonService: CommonService) {}

  public practice_name = this._commonService.practice.name;
  public user_name = `${this._commonService.user?.first_name} ${this._commonService.user?.last_name}`;

  public toggleBanner($event: MouseEvent): void {
    $event.stopPropagation();
    this.devService.isImpersonationVisible = !this.devService.isImpersonationVisible;
  }

  public async logout($event: MouseEvent): Promise<void> {
    if (!this.devService.isImpersonationVisible) return;
    $event.stopPropagation();
    await this._commonService.logout();
  }

  public goToTools($event: MouseEvent): void {
    if (!this.devService.isImpersonationVisible) return;
    $event.stopPropagation();
    this._navigationService.navigate(Constants.ROUTE_SUPPORT_TOOLS);
  }
}
