<header class="fixed top-0 left-72 right-0 z-30 mx-auto h-16 bg-white/80 shadow-sm backdrop-blur">
  <div class="mx-auto flex h-full max-w-screen-xl items-center justify-between px-16">
    <div class="flex items-center">
      <div class="flex items-center">
        <div class="flex items-center" [ngClass]="{ 'mr-3': saverService.saverStatus }">
          <button class="button-tertiary button-small" (click)="portalViewerService.preparePortalViewer()" tabindex="0" e2e-data-view-portal-button>
            <span>Preview Portal</span>
            <ng-icon name="heroArrowRight" size="20" class="ml-2 text-gray-900"></ng-icon>
          </button>
        </div>
        <dentr-saver></dentr-saver>
      </div>
    </div>

    <div *ngIf="devService.shouldShowSparkles" class="relative flex items-center justify-between">
      <dentr-dev-button [item_id]="jwtService.getJWT().practice_id" [item_type]="'Practice'"></dentr-dev-button>
      <span class="button-text text-lg text-white">{{ commonService.practice.name }}</span>
    </div>

    <div class="flex items-center justify-between">
      <div class="relative ml-5 flex items-center justify-end">
        <div class="mr-4" *ngIf="intercom_exists">
          <dentr-dropdown>
            <dentr-dropdown-trigger>
              <span class="flex cursor-pointer items-center justify-center">
                <ng-icon name="heroLifebuoy" class="text-gray-500" size="24"></ng-icon>
              </span>
            </dentr-dropdown-trigger>
            <dentr-dropdown-content>
              <button (click)="openHelp()" class="flex w-full cursor-pointer items-center px-4 py-3 text-sm hover:bg-gray-100">
                <ng-icon name="heroDocumentText" size="22" class="text-grape-500 mr-3"></ng-icon>
                Help articles
              </button>
              <button (click)="openIntercom()" class="flex w-full cursor-pointer items-center px-4 py-3 text-sm hover:bg-gray-100">
                <ng-icon name="heroChatBubbleOvalLeftEllipsis" size="22" class="text-grape-500 mr-3"></ng-icon>
                Chat with us
              </button>
            </dentr-dropdown-content>
          </dentr-dropdown>
        </div>

        <dentr-dropdown *ngIf="commonService.user">
          <dentr-dev-button [item_id]="jwtService.getJWT().user_id.toString()" [item_type]="'LoggedInUser'" [show_history]="false"></dentr-dev-button>
          <dentr-dropdown-trigger>
            <span class="cursor-pointer">
              <dentr-avatar size="xs" [avatar]="commonService.user" [use_initials]="true"></dentr-avatar>
            </span>

            <!-- <button
              class="dropdown-menu dropdown-menu-dark"
              [ngClass]="{ 'dropdown-menu-winter': commonService.enableWinter, 'dropdown-menu-dark': !commonService.enableWinter }"
            >
              <div class="flex items-center justify-between">
                <div class="flex flex-col">
                  <span>{{ commonService.user.first_name }} {{ commonService.user.last_name }}</span>
                </div>
              </div>
            </button> -->
          </dentr-dropdown-trigger>
          <dentr-dropdown-content width="56">
            <div class="flex items-center px-4 py-3">
              <dentr-avatar size="sm" [avatar]="commonService.user"></dentr-avatar>
              <div class="ml-3">
                <p class="font-medium capitalize text-gray-700">{{ commonService.user.first_name }} {{ commonService.user.last_name }}</p>
                <p class="text-sm font-medium text-gray-500">L{{ jwtService.getJWT().permission_level }} {{ commonService.user.role }}</p>
              </div>
            </div>
            <button (click)="commonService.logout()" class="flex w-full cursor-pointer items-center px-4 py-3 text-sm hover:bg-gray-100">
              <ng-icon name="heroArrowLeftOnRectangle" size="22" class="mr-3"></ng-icon>
              Logout
            </button>
          </dentr-dropdown-content>
        </dentr-dropdown>
      </div>
    </div>
  </div>
</header>
