<div class="text-sm text-gray-300">
  <p class="flex flex-col">
    <span class="flex">
      <span class="flex flex-col overflow-hidden">
        <button
          data-unit-test-resync
          class="flex items-center text-sm"
          (click)="resync()"
          [ngClass]="{
            'cursor-default': commonService.isRefreshing,
            'cursor-pointer': !commonService.isRefreshing
          }"
        >
          <ng-icon name="heroArrowPathSolid" size="12" class="mr-1.5" [ngClass]="{ 'animate-spin': commonService.isRefreshing }"></ng-icon>
          <span>Refresh Dentally Data</span>
        </button>
        <span class="overflow-hidden text-ellipsis whitespace-nowrap text-xs">
          <span>{{ commonService.refreshMessage }}</span>

          <span *ngIf="commonService.isRefreshing" class="block"> Last refreshed {{ commonService.lastCompleteSyncTime.fromNow() }} </span>
        </span>
      </span>
    </span>
  </p>
</div>
