import { E_ResourceType } from "../_common/types";

export class SiteSettingsBase {
  public site_id: string;

  // Practice Notifications
  public comms__booking_new_patient__practice_email = true;
  public comms__booking_existing_patient__practice_email = false;
  public comms__booking_cancellation__practice_email = true;
  public comms__booking_patient_comment__practice_email = false;

  // Patient Notifications
  public comms__booking_confirmation__patient_email = true;
  public comms__booking_confirmation__patient_sms = true;
  public comms__booking_cancellation__patient_email = true;
  public comms__booking_cancellation__patient_sms = true;

  // Task Reminders
  public comms__task_reminders__patient_email = false;
  public comms__task_reminders__patient_sms = false;

  // Forms (Online Signing)
  public forms__nhs_pr = false;
  public forms__medical_history = false;
  public forms__treamtent_plan_estimates = false;

  // Payment Types
  public payments__balance_payments = false;
  public payments__invoice_payments = false;

  /**
   * Scans through the fields on an updated SiteSettings item to determine the kind of resource being updated (required for L3 permissions on different columns of the same row)
   * @param item The update item received by GraphQL
   * @returns The resource type that matches the sub type of SiteSettings
   */
  public static updateItemToUpdateResourceType(item: SiteSettingsBase): E_ResourceType {
    for (const fieldName of Object.keys(item)) {
      const result = SiteSettingsBase.fieldToResourceType(fieldName);
      if (result != null) return result;
    }

    throw new Error("Unable to identify SiteSetting update type from input fields (685e3721)");
  }

  private static readonly _RESOURCE_FIELD_MAP = new Map<E_ResourceType, Array<string>>([
    [
      E_ResourceType.SiteSettings_PracticeNotifications,
      [
        "comms__booking_new_patient__practice_email",
        "comms__booking_existing_patient__practice_email",
        "comms__booking_cancellation__practice_email",
        "comms__booking_patient_comment__practice_email",
      ],
    ],
    [
      E_ResourceType.SiteSettings_PatientNotifications,
      [
        "comms__booking_confirmation__patient_email",
        "comms__booking_confirmation__patient_sms",
        "comms__booking_cancellation__patient_email",
        "comms__booking_cancellation__patient_sms",
      ],
    ],
    [E_ResourceType.SiteSettings_TaskReminderNotifications, ["comms__task_reminders__patient_email", "comms__task_reminders__patient_sms"]],
    [E_ResourceType.SiteSettings_OnlineSigning, ["forms__nhs_pr", "forms__medical_history", "forms__treamtent_plan_estimates"]],
    [E_ResourceType.SiteSettings_PaymentTypes, ["payments__balance_payments", "payments__invoice_payments"]],
  ]);

  public static fieldToResourceType(field: string): E_ResourceType | null {
    for (const keyValuePair of SiteSettingsBase._RESOURCE_FIELD_MAP) {
      if (keyValuePair[1].includes(field)) return keyValuePair[0];
    }
    return null;
  }

  public static resourceTypeToFields(resourceType: E_ResourceType): Array<string> {
    const fields = SiteSettingsBase._RESOURCE_FIELD_MAP.get(resourceType);
    if (fields) return fields;
    throw new Error("Resource is not a SiteSettings resource! (71117f6c)");
  }

  constructor(site_id?: string) {
    if (site_id) this.site_id = site_id;
  }
}
