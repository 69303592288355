import { Injectable } from '@angular/core';
import { FeaturesEntryUpdate } from 'src/app/data_model/features';
import { LoaderOverlayComponent } from '../components/loader/loader-overlay/loader-overlay.component';
import { HttpService } from './http.service';
import { OverlayService } from './overlay.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  constructor(private _httpService: HttpService, private _overlayService: OverlayService) {}

  public updateFeature(property: keyof FeaturesEntryUpdate, value: boolean): void {
    this._overlayService.open({ component: LoaderOverlayComponent });
    this._httpService
      .mutation(
        `mutation {
          updateFeatures(updated_item: {
            ${property}: ${value}
        })}`
      )
      .subscribe(() => {
        this._overlayService.close();
      });
  }
}
