import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ApplicationRef, ComponentRef, Injectable, Injector, EmbeddedViewRef } from '@angular/core';
import { Subject } from 'rxjs';

export class OverlayInstance {
  public component: ComponentType<any>;
  public data?: Record<string, any>;
}

export enum E_OverlayResult {
  DONE,
  SAVE,
  CONTINUE,
  CANCEL,
  UNDEFINED,
}

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  public onOverlayChange: Subject<boolean> = new Subject<boolean>();
  private _componentRefs: Array<ComponentRef<ComponentType<any>>> = [];
  private _onContinueMap = new Map<number, () => void>();
  private _overlayData: Record<string, any>;
  constructor(private _overlay: Overlay, private _appRef: ApplicationRef, private _injector: Injector) {}

  private _onContinue(): void {
    const callback = this._onContinueMap[this._componentRefs.length];
    delete this._onContinueMap[this._componentRefs.length];
    if (callback) callback();
  }

  public open(overlay: OverlayInstance, onContinue?: () => void): void {
    this._overlayData = overlay.data || {};
    // If there is already an overlay open, close it
    if (this._componentRefs.length) this.close();

    const overlayRef = this._overlay.create();
    const opening = new ComponentPortal(overlay.component);
    const componentRef = overlayRef.attach(opening);

    this._componentRefs.push(componentRef);
    this._onContinueMap[this._componentRefs.length] = onContinue;
    this._appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.onOverlayChange.next(true);
    document.body.appendChild(domElem);
  }

  public get data(): Record<string, any> {
    return this._overlayData;
  }

  public close(result: E_OverlayResult = E_OverlayResult.UNDEFINED): void {
    if (!this._componentRefs.length) return;

    if (result === E_OverlayResult.CONTINUE) this._onContinue();

    const closing = this._componentRefs.pop();

    if (closing) this._appRef.detachView(closing.hostView);
    if (this._componentRefs.length === 0) this.onOverlayChange.next(false);
    if (closing) closing.destroy();
  }

  public closeAll(result: E_OverlayResult = E_OverlayResult.UNDEFINED): void {
    if (!this._componentRefs.length) return;
    if (result === E_OverlayResult.CONTINUE) this._onContinue();
    for (const _ of this._componentRefs) {
      this.close();
    }
  }
}
