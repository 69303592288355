<dentr-modal>
  <div class="align-items: center flex justify-between">
    <h2 class="heading-xl">{{ data.objectType }}</h2>
    <div>
      <button class="button-primary" (click)="copy()">Copy</button>
      <button class="button-tertiary ml-2" (click)="close()">Close</button>
    </div>
  </div>
  <div #modalScroll>
    <pre><p class="text-sm overflow-x-auto mt-4">{{ data.object | json }}</p></pre>
  </div>
</dentr-modal>
