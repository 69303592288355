<div class="fixed top-10 z-40 flex h-screen min-h-full w-full flex-col">
  <div class="align-items: center flex justify-between">
    <h4 class="heading-xl">{{ data.objectType }}</h4>
    <div>
      <button class="button-tertiary ml-2" (click)="close()">Close</button>
    </div>
  </div>
  <div class="flex flex-col overflow-auto">
    <table class="border-spacing-1 mb-4 mt-2 border-separate">
      <thead>
        <tr>
          <th class="bg-slate-700 p-2 text-white" *ngFor="let header of data.formattedHeaders">
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.object">
          <ng-container *ngFor="let header of data.headers">
            <td
              class="p-2"
              [ngClass]="{
                'bg-slate-200 font-normal': data.derivedFields.includes(header) || !data.changeMap.get(row)?.includes(header),
                'bg-yellow-400 font-bold': !data.derivedFields.includes(header) && data.changeMap.get(row)?.includes(header),
                'text-gray-400': data.derivedFields.includes(header),
                'text-black': !data.derivedFields.includes(header)
              }"
            >
              {{ row[header] }}
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>
