import { Component, OnInit } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroQuestionMarkCircleSolid } from '@ng-icons/heroicons/solid';

@Component({
  selector: 'dentr-info-block',
  templateUrl: './info-block.component.html',
  standalone: true,
  imports: [NgIconComponent],
  providers: [provideIcons({ heroQuestionMarkCircleSolid })],
})
export class InfoBlockComponent implements OnInit {
  ngOnInit() {}
}
