export class LoggedInUserBase {
  public created_at: Date;
  public email: string;
  public first_name: string;
  public id: string;
  public image_url: string;
  public intercom_hash: string;
  public last_name: string;
  public role: string;
  public title: string;
  public permission_level: number;
}
