<div
  data-e2e-overlay
  (click)="close($event)"
  #modal
  [ngClass]="{
    'fixed inset-0 ': !gridLayout,
    'relative top-10': gridLayout
  }"
  class="z-40"
  role="dialog"
  aria-modal="true"
  @scaleAnimation
>
  <div class="flex justify-center">
    <div
      [ngClass]="{
        'fixed top-10': !gridLayout,
        'w-[900px]': !autoWidth,
        'w-auto': autoWidth
      }"
      class="inline-block max-w-screen-lg transform rounded-3xl bg-white p-10 text-left align-bottom shadow-xl transition-all"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
