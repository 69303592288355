<!-- https://github.com/picturepan2/devices.css -->
<div
  [ngClass]="{
    'device-has-content': hasContent
  }"
>
  <dentr-devices-iphone-14-pro *ngIf="device === DEVICES.IPHONE_14_PRO">
    <ng-container *ngTemplateOutlet="tmpl"></ng-container>
  </dentr-devices-iphone-14-pro>
</div>

<ng-template #tmpl>
  <div #content class="h-full">
    <video poster="assets/devices/{{ video.poster }}" autoplay loop muted class="device-content-placeholder" *ngIf="(!hasContent || showVideo) && video.src">
      <source src="assets/devices/{{ video.src }}" type="video/{{ video.type }}" />
    </video>
    <div class="device-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
