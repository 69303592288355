import { Component } from '@angular/core';
import { PortalSantaComponent } from '../shared/components/portal-santa/portal-santa.component';
import { CommonService } from '../shared/services/common.service';
import { DevService } from '../shared/services/dev.service';
import { SHARED } from '../shared/shared';
import { FADE_IN_ANIMATION } from '../shared/utils/animations';
import { ContentComponent } from './components/content/content.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';

@Component({
  selector: 'dentr-main',
  templateUrl: './main.component.html',
  standalone: true,
  imports: [SHARED, HeaderComponent, NavbarComponent, ContentComponent, PortalSantaComponent],
  animations: [FADE_IN_ANIMATION],
})
export class MainComponent {
  constructor(public commonService: CommonService, public devService: DevService) {
    const spinner = document.getElementById('app_loader_spinner');
    if (spinner) spinner.outerHTML = '';
  }

  public get showSanta(): boolean {
    return this.commonService.enableWinter && this.commonService.showSanta;
  }
}
