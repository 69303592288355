import { SHARED } from 'src/app/shared/shared';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClientDevicesService } from 'src/app/shared/services/client-devices.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'dentr-devices-iphone-14-pro',
  templateUrl: './iphone-14-pro.component.html',
  standalone: true,
  imports: [SHARED],
})
export class DevicesIphone14ProComponent implements OnInit, OnDestroy {
  private _subs = new SubSink();
  public currentTime: string;
  public batteryLevel = 100;
  constructor(private _clientDevicesService: ClientDevicesService) {}

  public ngOnInit(): void {
    this._subs.sink = this._clientDevicesService.onTimeChanged.subscribe((time) => {
      this.currentTime = time;
    });

    this._subs.sink = this._clientDevicesService.onBatteryLevelChanged.subscribe((batteryLevel) => {
      this.batteryLevel = batteryLevel;
    });
  }

  public ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
