import { E_ResourceType } from "../../common/schema";
export { E_ResourceType };

export type Constructor<T> = new (...args: any[]) => T;

export enum E_Timestream_Ranges {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  DAYS_7 = "DAYS_7",
  DAYS_30 = "DAYS_30",
  MONTHS_6 = "MONTHS_6",
  MONTHS_12 = "MONTHS_12",
}

export enum E_Action {
  cache = "cache",
  create = "create",
  delete = "delete",
  list = "list",
  read = "read",
  update = "update",
  manage = "manage", // all crud
}

export class DataApiEntry {
  public name: string;
  public value: any;
  public cast?: string;
}

export class JoinInfo {
  public selectStatement: string | null;
  public joinStatement: string;
}

export type ResolverMap = Map<E_ResourceType, Constructor<any>>;
