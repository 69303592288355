import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { Metric } from '@backend/common/analytics/metrics';

export { METRIC } from '@backend/common/analytics/metrics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private _httpService: HttpService) {}

  public async track(metric: Metric): Promise<void> {
    const { metricName, properties } = metric;
    // Given this a generic name as using e.g. 'analytics' or 'track'
    // can cause the request to be blocked by some browsers (e.g. arc).
    await this._httpService.post('/v2/manage', { metricName, properties }).toPromise();
  }
}
