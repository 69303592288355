import { generateNanoId } from "@shared/utils";
import { forOwn } from "lodash";
import { getDomain } from "@shared/new-domain";
import env from "../../common/env";

export class DefaultDomainBase {
  public domain: string;
  public id: string;

  public constructor(baseItem?: any, practiceId?: string) {
    if (!baseItem || !baseItem.id) {
      this.id = generateNanoId();
      if (!baseItem) return;
    }

    forOwn(baseItem, (value, key) => {
      this[key] = value;
    });

    if (this.domain && practiceId) {
      const { REGION, STAGE } = env;

      // HACK to use new domains for practices which have been migrated to new domain
      this.domain = getDomain(practiceId, this.domain, STAGE, REGION);
    }
  }
}
