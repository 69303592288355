import { HttpClient } from '@angular/common/http';
import { EnvService } from './env.service';
import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private _http: HttpClient, private _envService: EnvService, private _notificationService: NotificationService) {}

  public async post_rest<T>(endpoint: string, body?: any, error_action: string | null = null, showReload = true): Promise<T> {
    try {
      const url = `${this._envService.env.REST_URL}/api${endpoint}`;
      const p = this._http.post<T>(url, body).pipe().toPromise();
      const response: any = await p;
      if (response.errors) throw new Error('loading issue');
      return response.data;
    } catch (err) {
      if (error_action) this._notificationService.reportWarning(`Failed to ${error_action}`, err, showReload);
      throw err;
    }
  }

  private _graphRequest<T>(query: string, options: any = {}): Observable<T | any> {
    options.observe = 'response';
    options.body = {
      query,
    };

    return this._http.post<T>(this._graphUrl, { query }, options).pipe(
      map((res: any) => {
        if (res && res.ok === true) {
          return res.body?.data;
        }

        return res;
      })
    );
  }

  public query<T>(query: string, options: any = {}): Observable<T | any> {
    return this._graphRequest(query, options);
  }

  public mutation<T>(query: string, options: any = {}): Observable<T | any> {
    return this._graphRequest(query, options);
  }

  public async fetchData<T>(queryString: string, error_action: string | null = null, showReload = true): Promise<T> {
    try {
      const response: any = await this._http.post<T>(this._graphUrl, { query: queryString }).pipe().toPromise();

      if (response.errors) throw new Error(response.errors[0].message);

      return response.data;
    } catch (err) {
      console.error('error fetching data from graph', err);
      if (error_action) this._notificationService.reportWarning(`Failed to ${error_action}`, err, showReload);
      throw err;
    }
  }

  public post(url: string, body: any): Observable<any> {
    url = this._envService.env.REST_LEGACY_URL + url;
    return this._http.post(url, body);
  }

  private get _graphUrl(): string {
    return this._envService.env.GRAPH_URL;
  }
}
