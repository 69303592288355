import { Component, OnInit } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroArrowSmallRight } from '@ng-icons/heroicons/outline';
import { OverlayService } from 'src/app/shared/services/overlay.service';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'dentr-feature-upgrade-modal',
  templateUrl: './feature-upgrade-modal.component.html',
  standalone: true,
  imports: [ModalComponent, NgIconComponent],
  providers: [provideIcons({ heroArrowSmallRight })],
})
export class FeatureUpgradeModalComponent implements OnInit {
  constructor(private _overlayService: OverlayService) {}

  public ngOnInit(): void {}

  public close(): void {
    this._overlayService.close();
  }

  public openIntercom(): void {
    window.Intercom('showNewMessage', "Hi! I'm interested in upgrading my Dentally Portal features");
  }
}
