import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private _router: Router, public zone: NgZone) {}

  public navigate(path: string, params?: any) {
    let navLink = `/${path}`;
    navLink = navLink.replace(/\/\//, '/');
    this.zone.run(() => {
      this._router.navigate([navLink], params).then(
        () => {},
        () => {}
      );
    });
  }
}
