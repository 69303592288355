<div class="device device-iphone-14-pro">
  <div class="device-top-bar">
    <div class="device-top-bar-left">
      <span class="device-time">{{ currentTime }}</span>
    </div>
    <div class="device-top-bar-right">
      <div class="device-signal">
        <span></span>
      </div>
      <span class="device-network">5G</span>
      <div class="device-battery">
        <div class="device-battery-level" [ngStyle]="{ width: batteryLevel + '%' }"></div>
      </div>
    </div>
  </div>
  <div class="device-frame">
    <ng-content></ng-content>
  </div>
  <div class="device-stripe"></div>
  <div class="device-header"></div>
  <div class="device-sensors"></div>
  <div class="device-btns"></div>
  <div class="device-power"></div>
</div>
