import { Component, Input, OnInit } from '@angular/core';
import { JWTService } from 'src/app/shared/services/jwt.service';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { Constants } from 'src/constants';
import { DevService } from 'src/app/shared/services/dev.service';
import { SHARED } from 'src/app/shared/shared';
import { RefreshCacheComponent } from '../refresh-cache/refresh-cache.component';
import { SiteSwitcherComponent } from 'src/app/shared/components/site-switcher/site-switcher.component';
import { ACCORDION_ANIMATION } from 'src/app/shared/utils/animations';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  heroSparkles,
  heroUser,
  heroChevronRight,
  heroCalendar,
  heroPencil,
  heroCreditCard,
  heroBell,
  heroSwatch,
  heroDeviceTablet,
} from '@ng-icons/heroicons/outline';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FeatureFlagsService } from 'src/app/shared/services/feature-flags.service';

// Keep these as camelCase as they are used for interpolation in the template
export enum E_NavItemId {
  ONLINE_BOOKING = 'onlineBooking',
  ONLINE_SIGNING = 'onlineSigning',
  PATIENT_DETAILS = 'patientDetails',
  PAYMENTS = 'payments',
  DEVICES = 'devices',
  NOTIFICATIONS = 'notifications',
  CUSTOMISE = 'customise',
  DEV_MODE = 'devMode',
}

export interface I_NavChildItem {
  title: string;
  route: string;
  unitTestSelector: string;
  e2eTestSelector: string;
  show?: boolean;
}

export interface I_NavItem {
  id: E_NavItemId;
  title: string;
  route: string;
  icon: string;
  subItems?: Array<I_NavChildItem>;
  dropdown?: T_Dropdowns;
  unitTestSelector: string;
  e2eTestSelector: string;
  show?: boolean;
}

export type T_Dropdowns = 'onlineBooking' | 'customise' | 'onlineSigning' | 'devices' | 'payments' | 'devMode' | 'notifications' | 'patientDetails';

@Component({
  selector: 'dentr-navbar',
  templateUrl: './navbar.component.html',
  host: { class: 'fixed top-0 bottom-0 left-0 border-r w-72 flex flex-col justify-between bg-gray-800 text-gray-300' },
  standalone: true,
  imports: [RouterModule, SHARED, NgIconComponent, RefreshCacheComponent, SiteSwitcherComponent, AngularSvgIconModule],
  animations: [ACCORDION_ANIMATION],
  providers: [provideIcons({ heroSparkles, heroUser, heroChevronRight, heroCalendar, heroDeviceTablet, heroPencil, heroCreditCard, heroBell, heroSwatch })],
})
export class NavbarComponent implements OnInit {
  @Input() practiceName: string;
  public site_url = window.location.origin;
  public admin_url: string;
  public navItems = Array<I_NavItem>();
  public activeRoute = '';
  public dropdowns: Record<T_Dropdowns, boolean> = {
    onlineBooking: false,
    customise: false,
    onlineSigning: false,
    devices: false,
    payments: false,
    devMode: false,
    notifications: false,
    patientDetails: false,
  };
  public CONSTANTS: typeof Constants;
  public customiseDefaultRoute: string;

  constructor(
    public commonService: CommonService,
    public featureFlagsService: FeatureFlagsService,
    public jwtService: JWTService,
    public devService: DevService,
    private _router: Router,
    private _navigationService: NavigationService
  ) {
    this.admin_url = this.jwtService.getJWT().admin_url;
    this.CONSTANTS = Constants;
  }

  public toggleDropdown(dropdown: T_Dropdowns): void {
    this.dropdowns[dropdown] = !this.dropdowns[dropdown];
  }

  private _generateNavItems(): void {
    this._generateDefaultRoutes();
    this.navItems = [
      {
        id: E_NavItemId.ONLINE_BOOKING,
        title: 'Online Booking',
        route: Constants.ROUTE_ONLINE_BOOKING_APPOINTMENTS,
        icon: 'heroCalendar',
        dropdown: 'onlineBooking',
        e2eTestSelector: 'online-booking',
        unitTestSelector: 'online-booking',
        subItems: [
          {
            title: 'Appointments',
            route: Constants.ROUTE_ONLINE_BOOKING_APPOINTMENTS,
            e2eTestSelector: 'online-booking-appointments',
            unitTestSelector: 'online-booking-appointments',
          },
          {
            title: 'Plans & pricing',
            route: Constants.ROUTE_ONLINE_BOOKING_PLANS,
            unitTestSelector: 'online-booking-plans-and-pricing',
            e2eTestSelector: 'online-booking-plans-and-pricing',
          },
          {
            title: 'Settings',
            route: Constants.ROUTE_ONLINE_BOOKING_SETTINGS,
            unitTestSelector: 'online-booking-settings',
            e2eTestSelector: 'online-booking-settings',
            show: this.commonService.feat_OnlineBookingSettings,
          },
        ],
      },
      {
        id: E_NavItemId.ONLINE_SIGNING,
        title: 'Online Signing',
        route: Constants.ROUTE_ONLINE_SIGNING_DOCUMENT_TYPES,
        icon: 'heroPencil',
        dropdown: 'onlineSigning',
        e2eTestSelector: 'online-signing',
        unitTestSelector: 'online-signing',
        subItems: [
          {
            title: 'Document types',
            route: Constants.ROUTE_ONLINE_SIGNING_DOCUMENT_TYPES,
            e2eTestSelector: 'online-signing-document-types',
            unitTestSelector: 'online-signing-document-types',
          },
          {
            title: 'Settings',
            route: Constants.ROUTE_ONLINE_SIGNING_SETTINGS,
            unitTestSelector: 'online-signing-settings',
            e2eTestSelector: 'online-signing-settings',
            show: this.commonService.isL4OrHigher,
          },
        ],
      },
      {
        id: E_NavItemId.PATIENT_DETAILS,
        title: 'Patient Details',
        route: Constants.ROUTE_PATIENT_DETAILS_DATA,
        icon: 'heroUser',
        dropdown: 'patientDetails',
        e2eTestSelector: 'patient-profile',
        unitTestSelector: 'patient-profile',
        subItems: [
          {
            title: 'Types of data',
            route: Constants.ROUTE_PATIENT_DETAILS_DATA,
            e2eTestSelector: 'patient-profile-data',
            unitTestSelector: 'patient-profile-data',
          },
        ],
      },
      {
        id: E_NavItemId.PAYMENTS,
        title: 'Payments',
        route: Constants.ROUTE_PAYMENTS_TYPES,
        icon: 'heroCreditCard',
        dropdown: 'payments',
        e2eTestSelector: 'payments',
        unitTestSelector: 'payments',
        show: this.commonService.feat_Payments,
        subItems: [
          {
            title: 'Payment types',
            route: Constants.ROUTE_PAYMENTS_TYPES,
            e2eTestSelector: 'payments-payment-types',
            unitTestSelector: 'payments-payment-types',
          },
          {
            title: 'Link with Stripe',
            route: Constants.ROUTE_PAYMENTS_LINK_WITH_PAYMENT_PROVIDER,
            e2eTestSelector: 'payments-link-with-payment-provider',
            unitTestSelector: 'payments-link-with-payment-provider',
            show: this.commonService.isL4OrHigher,
          },
          {
            title: 'Settings',
            route: Constants.ROUTE_PAYMENTS_SETTINGS,
            unitTestSelector: 'payments-settings',
            e2eTestSelector: 'payments-settings',
            show: this.commonService.isL4OrHigher,
          },
        ],
      },
      {
        id: E_NavItemId.DEVICES,
        title: 'Devices',
        route: Constants.ROUTE_DEVICES_PAIR,
        icon: 'heroDeviceTablet',
        dropdown: 'devices',
        e2eTestSelector: 'devices',
        unitTestSelector: 'devices',
        //only show this menu item if the feature flag is enabled
        //when the feature flag is removed, this menu item will show for everyone,
        //but if the user tries to pair without having the iOS Houston toggle enabled,
        //they will see the Upgrade Today modal
        show: this.featureFlagsService.devices,
        subItems: [
          { title: 'Pair', route: Constants.ROUTE_DEVICES_PAIR, e2eTestSelector: 'devices-link', unitTestSelector: 'devices-link' },
          {
            title: 'Settings',
            route: Constants.ROUTE_DEVICES_SETTINGS,
            e2eTestSelector: 'devices-settings',
            unitTestSelector: 'devices-settings',
            show: this.commonService.isL4OrHigher && this.commonService.feat_L2_L1_Login,
          },
        ],
      },
      {
        id: E_NavItemId.NOTIFICATIONS,
        title: 'Patient Notifications',
        route: Constants.ROUTE_PATIENT_NOTIFICATION_TYPES,
        icon: 'heroBell',
        dropdown: 'notifications',
        e2eTestSelector: 'patient-notifications',
        unitTestSelector: 'patient-notifications',
        show: this.commonService.isL4OrHigher,
        subItems: [
          {
            title: 'Notification types',
            route: Constants.ROUTE_PATIENT_NOTIFICATION_TYPES,
            unitTestSelector: 'patient-notifications-types',
            e2eTestSelector: 'patient-notifications-types',
          },
          {
            title: 'Settings',
            route: Constants.ROUTE_PATIENT_NOTIFICATION_SETTINGS,
            unitTestSelector: 'patient-notifications-settings',
            e2eTestSelector: 'patient-notifications-settings',
          },
        ],
      },
      {
        id: E_NavItemId.CUSTOMISE,
        title: 'Customise',
        route: this.customiseDefaultRoute,
        icon: 'heroSwatch',
        dropdown: 'customise',
        e2eTestSelector: 'customise',
        unitTestSelector: 'customise',
        show: this.customiseFeaturesEnabled,
        subItems: [
          {
            title: 'Brands',
            route: Constants.ROUTE_CUSTOMISE_BRANDS,
            unitTestSelector: 'customise-brands',
            e2eTestSelector: 'customise-brands',
            show: this.commonService.feat_CustomiseBrands,
          },
          {
            title: 'Portal',
            route: Constants.ROUTE_CUSTOMISE_PORTAL,
            unitTestSelector: 'customise-portal',
            e2eTestSelector: 'customise-portal',
            show: this.commonService.feat_CustomisePortal,
          },
          {
            title: 'Practitioners',
            route: Constants.ROUTE_CUSTOMISE_PRACTITIONERS,
            e2eTestSelector: 'customise-practitioners',
            unitTestSelector: 'customise-practitioners',
            show: this.commonService.feat_CustomisePractitioners,
          },
          {
            title: 'Settings',
            route: Constants.ROUTE_CUSTOMISE_SETTINGS,
            unitTestSelector: 'customise-settings',
            e2eTestSelector: 'customise-settings',
            show: this.commonService.isL4OrHigher,
          },
        ],
      },
      {
        id: E_NavItemId.DEV_MODE,
        title: 'Dev / Sparkle Mode',
        route: Constants.ROUTE_DEV_MODE_BULK_DATA,
        icon: 'heroSparkles',
        dropdown: 'devMode',
        e2eTestSelector: 'dev-mode',
        unitTestSelector: 'dev-mode',
        show: this.devService.isUserDev,
        subItems: [
          {
            title: 'Bulk data',
            route: Constants.ROUTE_DEV_MODE_BULK_DATA,
            unitTestSelector: 'dev-mode-bulk-data',
            e2eTestSelector: 'dev-mode-bulk-data',
          },
          {
            title: 'Diagnostics',
            route: Constants.ROUTE_DEV_MODE_DIAGNOSTICS,
            unitTestSelector: 'dev-mode-diagnostics',
            e2eTestSelector: 'dev-mode-diagnostics',
          },
          {
            title: 'Links',
            route: Constants.ROUTE_DEV_MODE_LINKS,
            unitTestSelector: 'dev-mode-links',
            e2eTestSelector: 'dev-mode-links',
          },
          {
            title: 'Support tools',
            route: Constants.ROUTE_DEV_MODE_SUPPORT_TOOLS,
            unitTestSelector: 'dev-mode-support-tools',
            e2eTestSelector: 'dev-mode-support-tools',
          },
        ],
      },
    ];
  }

  public get customiseFeaturesEnabled(): boolean {
    return this.commonService.feat_CustomiseBrands || this.commonService.feat_CustomisePortal || this.commonService.feat_CustomisePractitioners;
  }

  public navigateTo(url) {
    this._navigationService.navigate(url);
  }

  private _generateDefaultRoutes() {
    switch (true) {
      case this.commonService.features.branding:
        if (this.commonService.isMultiSite) {
          this.customiseDefaultRoute = this.CONSTANTS.ROUTE_CUSTOMISE_BRANDS;
        } else {
          this.customiseDefaultRoute = this.CONSTANTS.ROUTE_CUSTOMISE_PORTAL;
        }
        break;
      case this.commonService.features.practitioner_profiles:
        this.customiseDefaultRoute = this.CONSTANTS.ROUTE_CUSTOMISE_PRACTITIONERS;
        break;
      default:
        // Fallback
        this._navigationService.navigate(this.CONSTANTS.ROUTE_ONLINE_BOOKING_APPOINTMENTS);
    }
  }

  private _handleRoute(route: string): void {
    for (const [key, _value] of Object.entries(this.dropdowns)) {
      const new_route = key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
      this.dropdowns[key] = route.includes(new_route);
    }
    this.activeRoute = route;
  }

  public ngOnInit(): void {
    this._generateNavItems();
    this._handleRoute(this._router.url);
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._handleRoute(event.url || event.urlAfterRedirects);
      }
    });
  }
}
