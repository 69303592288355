<div class="flex items-center" *ngIf="saverService.saverStatus" [ngSwitch]="saverService.saverStatus">
  <span class="flex items-center" *ngSwitchCase="SaverStatuses.SAVING">
    <ng-icon name="heroArrowPathSolid" size="18" class="mr-1.5 animate-spin"></ng-icon>
    <span data-e2e-updating-portal>Updating Portal</span>
  </span>
  <span class="flex items-center" *ngSwitchCase="SaverStatuses.ERROR">
    <ng-icon name="heroExclamationCircleSolid" size="18" class="mr-1.5 text-yellow-500"></ng-icon>
    <span>Error updating Portal</span>
  </span>
  <span class="flex items-center" *ngSwitchCase="SaverStatuses.SAVED">
    <ng-icon name="heroCheckCircleSolid" size="18" class="text-pine-500 mr-1.5"></ng-icon>
    <span data-e2e-saved-portal>Portal updated</span>
  </span>
</div>
