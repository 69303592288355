import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { JWTService } from 'src/app/shared/services/jwt.service';
import { OverlayService } from 'src/app/shared/services/overlay.service';
import { SHARED } from 'src/app/shared/shared';
import { requiredValidator } from 'src/app/shared/utils/validators';

@Component({
  selector: 'dentr-elevate-password',
  templateUrl: './elevate-password.component.html',
  standalone: true,
  imports: [SHARED, ModalComponent, ReactiveFormsModule],
})
export class ElevatePasswordComponent implements OnInit {
  public createPassForm: UntypedFormGroup;

  @ViewChild('passwordEl') passwordEl: ElementRef;

  constructor(
    public commonService: CommonService,
    private _overlayService: OverlayService,
    private _httpService: HttpService,
    private _fb: UntypedFormBuilder,
    private _jwtService: JWTService
  ) {}

  ngOnInit(): void {
    this._setupForm();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.passwordEl.nativeElement.focus();
    }, 300);
  }

  public async submit() {
    if (this.createPassForm.valid) {
      const passwordField = this.createPassForm.get('password');
      if (!passwordField) throw new Error('No password field');
      const GQL = `
      mutation {
        elevateToL5(password: "${passwordField.value}")
      }`;
      const response = await this._httpService.fetchData<any>(GQL);
      const newJwt = response?.elevateToL5;
      if (newJwt) {
        this._jwtService.jwt = newJwt;
        this.close();
      }
    }
  }

  public close() {
    this._overlayService.closeAll();
  }

  private _setupForm() {
    this.createPassForm = this._fb.group({
      password: ['', [requiredValidator(), Validators.minLength(64), Validators.maxLength(64)]],
    });
  }
}
