<div class="bg-grape-700 fixed z-50 h-full w-full">
  <button class="absolute top-5 right-5 z-50 cursor-pointer" (click)="hide()">
    <span class="sr-only">Close</span>
    <ng-icon name="heroXCircle" size="80" class="text-white"></ng-icon>
  </button>
  <div class="flex h-full w-full flex-col items-center justify-center">
    <div class="relative h-[340px] w-[340px] overflow-hidden rounded-[50%] border-[10px] border-solid border-[#f8e7dc] bg-[#a0d5d3]">
      <div class="absolute left-2/4 bottom-0 -translate-x-2/4">
        <div class="animate-headLaugh absolute left-2/4 z-[2] -translate-x-2/4">
          <div class="h-[130px] w-[120px] rounded-[50%] border-[3px] border-solid border-[#f8e7dc] bg-[#edcab0]">
            <div class="redhat">
              <div
                class="absolute left-2/4 top-0 z-[4] h-8 w-[90%] -translate-x-2/4 rounded-[50px] bg-[#f8e7dc] shadow-[0px_6px_0px_-4px_rgba(0,0,0,0.1)]"
              ></div>
              <div
                class="after:bg-grape-700; bg-grape-700 absolute top-[-50px] left-[15px] z-[-1] h-[120px] w-[120px] rounded-[50%] before:absolute before:left-0 before:top-3 before:h-[95px] before:w-[95px] before:rounded-[50%] before:shadow-[inset_-8px_-1px_0px_-5px_rgba(0,0,0,0.05)] before:content-['_'] after:absolute after:right-0 after:top-[60px] after:h-[50px] after:w-5 after:content-['_']"
              ></div>
              <div class="absolute -right-5 top-10 z-[5] h-[38px] w-[38px] rounded-[50%] bg-[#f8e7dc] shadow-[0px_6px_0px_-4px_rgba(0,0,0,0.1)]"></div>
            </div>
            <div
              class="absolute left-2/4 top-[57px] -translate-x-2/4 before:absolute before:left-[-28px] before:top-0 before:h-[9px] before:w-[15px] before:rounded-[50%] before:border-0 before:border-t-[5px] before:border-solid before:border-[#a8744f] before:content-['_'] after:absolute after:right-[-28px] after:top-0 after:h-[9px] after:w-[15px] after:rounded-[50%] after:border-0 after:border-t-[5px] after:border-solid after:border-[#a8744f] after:content-['_']"
            ></div>
            <div
              class="animate-beardLaugh absolute left-2/4 h-[55px] w-[55px] -translate-x-2/4 rounded-[50%] bg-[#f8e7dc] before:absolute before:-left-[40px] before:bottom-[15px] before:h-20 before:w-20 before:rounded-[50%] before:bg-[#f8e7dc] before:content-['_'] after:absolute after:-right-[40px] after:bottom-[15px] after:h-20 after:w-20 after:rounded-[50%] after:bg-[#f8e7dc] after:content-['_']"
            >
              <div
                class="absolute top-[-42px] left-2/4 z-[3] h-5 w-[25px] -translate-x-2/4 rounded-[50%] bg-[#e9a982] shadow-[inset_-3px_-3px_0px_#e9a982]"
              ></div>
              <div
                class="animate-mouthLaugh absolute left-2/4 top-0 z-[3] h-[5px] -translate-x-2/4 rounded-br-[80px_50px] rounded-bl-[80px_50px] bg-[#a8744f]"
              ></div>
            </div>
          </div>
          <div
            class="after:top-2/4; before:absolute before:-left-2 before:top-2/4 before:z-[-1] before:h-[30px] before:w-5 before:rotate-[-10deg] before:rounded-[50%] before:content-['_'] after:absolute after:-right-[8px] after:z-[-1] after:h-[30px] after:w-5 after:rotate-[-10deg] after:rounded-[50%] after:from-[#e9a982] after:to-[#edcab0] after:content-['_']"
          ></div>
        </div>
        <div
          class="bg-grape-700 animate-bodyLaugh relative h-[210px] w-[190px] translate-y-2/4 rounded-[50%] before:absolute before:left-2/4 before:top-[35%] before:h-[7px] before:w-[7px] before:-translate-x-2/4 before:-translate-y-2/4 before:rounded-[50%] before:bg-white before:shadow-[0px_-18px_0px_#ffffff,0px_18px_0px_#ffffff] before:content-['_']"
        ></div>
      </div>
    </div>
    <div class="font-mountainsOfChristmas mb-0 whitespace-nowrap text-[75px] not-italic leading-normal text-white">
      <h2 class="text-white">Seasons Greetings From Dentally Portal!</h2>
    </div>
  </div>
  <div class="animate-snow fixed inset-0 h-full w-full bg-[url('assets/snow-heavy.svg')]">
    <div class="animate-snow h-full w-full bg-[url('assets/snow-light.svg')]"></div>
  </div>
</div>
